<template>
  <div class="nav-container">
    <div class="content">
      <div class="row main-nav">
        <div class="nav-left row s_center">
          <router-link to="/loupan" class=" row  s_center">
            <img width="42" height="42" class="pr5" :src="companyConfig.greenLogo" alt="" />
            <span style="font-size:30px" class="green">{{ companyConfig.firstName }}新房</span>
          </router-link>
          <span class="nav-border"></span>
          <a @click="$emit('update-city-select', true)" href="javascript:;" class="s-city row s_center">
            {{ location.cityName }}
            <i class="el-icon-caret-bottom size10 whiteGray"></i>
          </a>
        </div>
        <div class="search-container row sb_center relative" @click.stop="">
          <input @focus="updateSearchTip(true)" @keyup.enter="handleSubmit" @input="onInput" class="flex1"
            :value="estateName" placeholder="请试试输入楼盘名" />
          <a @click="handleSubmit" href="javascript:;" class="search-btn">
            <i class="el-icon-search whiteGray size14"></i>
          </a>
          <div class="sug-tips">
            <search-tip :showReacommedEstate="true" :estateName="estateName" :tradeType="3"
              :showSearchTip="showSearchTip" />
          </div>
        </div>
      </div>
      <ul class="sel-list-nav bold row size16 black ">
        <li v-for="item in status" @click="handleStatus(item)" :key="item.type"
          :style="{ borderColor: companyConfig.themeColor }"
          :class="[chooseStatus === item.title ? 'choose green' : '']">
          {{ item.title }}</li>
        <li v-if="!isSourceERP">
          <router-link to="/newEstateMap" target="_blank" class="black">地图找房</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SearchTip from "@/components/search-tip.vue";
import { mapState } from "vuex";
export default {
  props: {
    estateName: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState(["location", "showSearchTip", "user", "companyConfig", "isSourceERP"]),
  },
  data() {
    return {
      chooseStatus: "全部楼盘",
      status: [
        {
          title: "全部楼盘",
          type: 1,
          params: {
            sort: "0", firstTime: '', activityType: ""
          }
        },
        {
          title: "最近开盘",
          type: 2,
          params: { sort: "4", firstTime: 3, activityType: "" }
        },
        {
          title: "优惠楼盘",
          type: 3,
          params: {
            activityType: "1", sort: "0", firstTime: ''
          }
        }
      ]
    };
  },
  components: {
    SearchTip
  },
  methods: {
    updateSearchTip(isShow) {
      this.$store.commit("updateState", {
        data: isShow,
        key: "showSearchTip"
      });
    },
    handleStatus(item) {
      if (this.chooseStatus === item.title) return;
      this.chooseStatus = item.title;
      this.$emit("on-refresh", item.params);
    },
    onInput(e) {
      const { value } = e.target;
      this.$emit("update-estate", value);
    },
    handleSubmit() {
      this.$emit("handle-submit");
      this.$store.commit("updateState", {
        data: false,
        key: "showSearchTip"
      });
    },
    handleFindHouse() {
      this.$emit("update-show-find-house", true);
    }
  }
};
</script>
<style lang="less" scoped>
.nav-container {
  margin-bottom: 20px;
  background: #f5f5f6;
  box-shadow: 0 1px 0 0 #eee;
  border-top: 1px solid #eee;
  padding-bottom: 4px;

  .main-nav {
    margin-bottom: 8px;
    padding-top: 22px;
  }

  .nav-border {
    width: 1px;
    height: 28px;
    margin: 0 16px;
    background: #ccc;
    display: inline-block;
  }

  .s-city {
    height: 26px;
    padding: 0 8px;
    line-height: 26px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    text-decoration: none;
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }

  .search-container {
    width: 450px;
    height: 46px;
    line-height: 46px;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 0 5px 0 #b3b3b35a;
    margin-left: 50px;

    input {
      display: block;
      width: 100%;
      height: 46px;
      margin: 0;
      padding: 0 0 0 22px;
      line-height: normal;
      font-size: 12px;
      border: none;
      box-sizing: border-box;
    }

    .search-btn {
      position: absolute;
      width: 50px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
    }

    .sug-tips {
      position: absolute;
      top: 6px;
      left: 0;
      width: 100%;
      z-index: 100;
    }
  }

  .sel-list-nav {
    li {
      margin-right: 35px;
      padding: 16px 0 10px;
      cursor: pointer;
    }

    .choose {
      border-bottom: 2px solid;
    }
  }
}
</style>
