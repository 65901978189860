<template>
  <div class="container">
    <header-wrap @update-city-select="isShowCitySelect = $event" @update-estate="estateName = $event"
      @update-show-find-house="showFindHouse = $event" @on-refresh="updateEstate" @handle-submit="handleSubmit"
      :estate-name="estateName" />
    <search-bar @update-children="updateChildren = $event" :updateChildren="updateChildren" :items="params"
      @sendParamsEmit="onRefresh" />
    <div class="content row sb_start " style="margin-top:20px">
      <div style="width:932px">
        <ul class="row sort-list-wrapper" :style="{ borderColor: companyConfig.themeColor }">
          <li v-for="item in sort" class="size16 cursor" :class="[choose === item.name ? 'white greenBG  bold' : '']"
            :key="item.key" @click="setChoose(item)">{{ item.name }}</li>
        </ul>
        <div class="row sb_center mb15">
          <div class="flex1 row">
            <b>筛选:</b>
            <ul class="row ml20">
              <li v-for="(hot, index) in hotSearchTag" v-show="(index === 0 ? !isSourceERP : true)" :key="hot.name"
                class="row s_center size13 whiteGray mr20 cursor" @click="handleHotSearchTag(index)">
                <div class="box mr5 row s_center" :class="[hot.choose ? 'choose-bottom-rengion' : 'whiteBG']">
                  <i v-show="hot.choose" class="el-icon-check white "></i>
                </div>
                <span :class="[hot.isActivity ? 'green' : '']">{{ hot.name }}</span>
              </li>
            </ul>
          </div>
          <a @click="handleClearParams" href="javascript:;" class="row s_center size14 black">
            <i class="el-icon-delete pr5"></i>
            清空条件
          </a>
        </div>

        <ul class="flex1">
          <estate-item v-for="item in estateList" :key="item.guid" :houseItem="item" />
          <load-more :no-more="noMore" load-text="楼盘加载中···" />
        </ul>
      </div>
      <div style="width:185px">
        <div class="map-room column c_center">
          <img src="@/assets/imgs/app_icon_map_room.png" alt="" />
          <router-link to="/newEstateMap" class="button greenBG" target="_blank">试试地图找房</router-link>
        </div>
        <div v-if="hotEstateList.length" class="mt30 hot-building-wrapper">
          <h1 class="title size16 black">热门楼盘</h1>
          <ul>
            <hot-estate-item v-for="item in hotEstateList" :key="item.guid" :houseItem="item" />
          </ul>
        </div>
      </div>
    </div>
    <!-- 右侧菜单栏 -->
    <right-silder v-if="!isSourceERP" :show-go-back="showGoBack" />
    <city-select :show-city-select="isShowCitySelect" @update-city-select="isShowCitySelect = $event" />
    <!-- 帮我找房 -->
    <help-me-find-house @update-show-find-house="showFindHouse = $event" :show-find-house="showFindHouse" :form="form"
      :formParam="formParam" />
  </div>
</template>

<script>
import HeaderWrap from "./components/header-wrap";
import EstateItem from "./components/estate-item";
import HotEstateItem from "./components/hot-estate-item";
import RightSilder from "./components/right-silder";
import SearchBar from "@/components/search-bar";
import CitySelect from "@/components/city-select";
import HelpMeFindHouse from "./components/help-me-find-house";
import LoadMore from "../../components/load-more";
import util from "@/utils/util";
import tool from "@/utils/tool";
import { getItem, setItem } from '../../utils/storage'
import { mapState } from "vuex";
import houseServer from "../../services/houseServer";
import Constant from "@/common/Constant";

let that;

export default {
  name: "estate",
  data() {
    return {
      params: [],
      sort: [],
      choose: "",
      hotSearchTag: [
        {
          key: "sellState",
          value: 1,
          name: "在售",
          choose: false
        },
        {
          value: 2,
          key: "propertyType",
          name: "住宅",
          choose: false
        },
        {
          value: "VR看房",
          key: "tag",
          name: "VR看房",
          choose: false
        },
        {
          param: {
            activityType: "1"
          },
          name: "优惠楼盘",
          isActivity: true,
          choose: false
        },
        {
          param: {
            sort: "4"
          },
          name: "近期开盘",
          isActivity: true,
          choose: false
        }
      ],
      searchParams: {
        sort: 0,
        page: 1,
        itemsPerPage: 25,
      },
      hotEstateList: [],
      estateList: [],
      isShowCitySelect: false,
      showFindHouse: false,
      noMore: false,
      loading: false,
      showGoBack: false,
      estateName: "",
      showHouseCard: false,
      form: {
        range: [100, 200]
      },
      formParam: {
        room: [],
        region: []
      },
      updateChildren: false //是否更新子组件
    };
  },
  computed: {
    ...mapState(["location", "companyConfig", "isSourceERP"])
  },
  watch: {
    location(newVal, preVal) {
      if (newVal.cityCode !== preVal.cityCode) {
        this.clearParams();
        if (this.searchParams.page === 1) {
          this.loadParams();
        }
        setTimeout(() => {
          this.loadEstateList();
        }, 1000);
      }
    },
    $route: {
      handler(newVal, val) {
        if (!val || newVal.query.name !== val.query.name) {
          const estateName = newVal.query.name;
          this.clearParams();
          this.estateName = estateName || "";
          this.searchParams = {
            page: 1,
            itemsPerPage: 25,
            name: this.estateName
          };
          setTimeout(() => {
            this.loadEstateList();
          }, 1000);
        }
      },
      immediate: true //第一次就监听
    }
  },
  components: {
    HeaderWrap,
    SearchBar,
    HotEstateItem,
    RightSilder,
    EstateItem,
    CitySelect,
    HelpMeFindHouse,
    LoadMore
  },
  methods: {
    async loadParams() {
      const [err, res] = await tool.to(
        util.getNewEstateParams(this.location.cityCode, this.isSourceERP)
      );
      if (err) {
        console.log("getNewEstateParams :>>", err);
        return;
      }
      // console.log('getNewEstateParams :>>',JSON.stringify(res));
      this.params = res;
      this.sort = res.filter(ele => ele.key === "sort")[0].data;
      const _formParam = {};
      res.forEach(_ => {
        if (_.key === "region" || _.key === "room") {
          _formParam[_.key] = _;
        }
      });
      this.choose = this.sort[0].name;
      this.formParam = _formParam;
      // console.log('_formParam :>>',JSON.stringify(_formParam));
    },
    async loadEstateList() {
      if (this.loading || this.noMore) return;
      this.loading = true;
      const params = {
        ...this.searchParams,
        cityCode: this.location.cityCode,
        area1: this.location.cityCode,
      };
      const [err, { data }] = await tool.to(
        houseServer.getNewEstatePage(params)
      );
      if (err) {
        console.log("getNewEstatePage :>>", err);
        this.loading = false;
        this.noMore = false;
        return;
      }
      this.loading = false;
      if (data) {
        if (params.page === 1) {
          this.hotEstateList = data.slice(0, 5);
        }
        this.estateList = [...this.estateList, ...data];
        if (data.length < params.itemsPerPage) {
          this.noMore = true;
        }
      }
      // console.log('getNewEstatePage :>>', JSON.stringify(data));
    },
    clearParams() {
      this.searchParams = {
        ...this.searchParams,
        sort: 0,
        page: 1,
        itemsPerPage: 25
      },
        this.noMore = false;
      this.loading = false;
      this.hotEstateList = [];
      this.estateList = [];
    },
    onLoadMore() {
      if (!this.noMore && !this.loading) {
        this.searchParams.page++;
        this.loadEstateList();
      }
    },
    handleHotSearchTag(index) {
      this.clearParams();
      const _hotSearchTag = [...this.hotSearchTag];
      _hotSearchTag[index].choose = !_hotSearchTag[index].choose;
      this.hotSearchTag = _hotSearchTag;
      const { key, value, choose, param } = _hotSearchTag[index];
      if (key) {
        const _prams = [...this.params];
        _prams.forEach(_ => {
          if (_.key === key) {
            if (choose) {
              _.value.push(value);
              this.searchParams = {
                ...this.searchParams,
                [key]: value
              };
            } else {
              _.value = _.value.filter(cur => cur !== value);
              delete this.searchParams[key];
            }
          }
        });
        this.params = _prams;
      } else {
        if (choose) {
          this.searchParams = {
            ...this.searchParams,
            ...param
          };
        } else {
          const _key = Object.keys(param)[0];
          delete this.searchParams[_key];
        }
      }

      this.loadEstateList();
    },
    setChoose(item) {
      if (this.choose === item.name) return;
      this.clearParams();
      this.choose = item.name;
      this.searchParams.sort = item.code;
      this.loadEstateList();
    },
    onRefresh({ data, isRefresh = true }) {
      this.params = data;
      util.createNewEstateSearch(this.searchParams, data);
      if (isRefresh) {
        const _hotSearchTag = [...this.hotSearchTag];
        const keys = ["tag", "sellState", "propertyType"];
        for (const key in this.searchParams) {
          const tags = this.searchParams[key];
          if (keys.includes(key)) {
            _hotSearchTag.forEach(_ => {
              if (_.key === key) {
                if (tags.includes(_.value)) {
                  _.choose = true;
                } else {
                  _.choose = false;
                }
              }
            });
          }
        }
        this.hotSearchTag = _hotSearchTag;
        // this.clearParams();
        this.noMore = false;
        this.loading = false;
        this.hotEstateList = [];
        this.estateList = [];
        this.loadEstateList();
      }
    },
    handleClearParams() {
      this.clearParams();
      if (this.searchParams.activityType) {
        delete this.searchParams.activityType;
      }
      this.searchParams = {
        sort: 0,
        page: 1,
        itemsPerPage: 25,
        sellState: this.isSourceERP ? [1, 2] : []
      };
      const _hotSearchTag = [...this.hotSearchTag].map(_ => ({
        ..._,
        choose: false
      }));
      const _prams = [...this.params].map(_ => {
        const res = {
          ..._,
          value: Array.isArray(_.value) ? [] : {}
        };
        _.input
          ? (res.input = {
            minValue: "",
            maxValue: ""
          })
          : "";
        return res;
      });
      this.hotSearchTag = _hotSearchTag;
      this.updateChildren = true;

      this.params = _prams;
      this.loadEstateList();
    },
    updateEstate(param) {
      this.clearParams();
      if (param.activityType) {
        delete this.searchParams.activityType;
      }
      this.searchParams = {
        ...this.searchParams,
        ...param
      };
      const key = Object.keys(param)[0];
      const _hotSearchTag = [...this.hotSearchTag].map(_ => ({
        ..._,
        choose: _.param && key && _.param[key] === param[key] ? true : false
      }));
      this.loadEstateList();
      this.hotSearchTag = _hotSearchTag;
    },
    handleScroll: tool.throttle(() => {
      const html = document.documentElement,
        body = document.body;
      const scrollTop = window.pageYOffset || body.scrollTop || html.scrollTop;
      const clientHeight =
        window.innerHeight || body.clientHeight || html.clientHeight;
      const scrollheight = body.scrollHeight || html.scrollHeight;
      if (scrollTop > clientHeight / 2) {
        that.showGoBack = true;
      } else {
        that.showGoBack = false;
      }
      if (scrollTop + clientHeight > scrollheight - 50) {
        that.onLoadMore();
      }
    }, 400),
    handleSubmit() {
      this.clearParams();
      this.searchParams = {
        ...this.searchParams,
        name: this.estateName
      };

      this.loadEstateList();
    },
    init() {
      const { query: { isSourceERP = false } } = this.$route;
      if (isSourceERP) {
        this.searchParams = {
          ...this.searchParams,
          sellState: [1, 2]
        };
      }
      if (getItem(Constant.IS_SORCE_ERP)) return
      this.$store.commit("updateState", {
        data: !!isSourceERP,
        key: "isSourceERP"
      });
      setItem(Constant.IS_SORCE_ERP, !!isSourceERP)
    },
  },
  created() {
    this.init()
    this.loadParams();
  },
  mounted() {
    that = this;
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="less" scoped>
.map-room {
  img {
    width: 120px;
    height: 120px;
  }

  .button {
    text-decoration: none;
    margin-top: 26px;
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 36px;
    font-weight: 700;
    cursor: pointer;
  }
}

.container {
  .slider {
    min-width: 1200px;
    width: 1200px;
    margin: 30px auto 120px;
  }

  .sort-list-wrapper {
    margin-bottom: 20px;
    border-bottom: 2px solid;

    li {
      height: 50px;
      line-height: 50px;
      padding: 0 24px;
    }
  }

  .box {
    width: 14px;
    height: 14px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(204, 204, 204, 0.4) inset;
  }

  .hot-building-wrapper {
    .title {
      line-height: 22px;
      font-weight: 700;
    }
  }

  .choose-bottom-rengion {
    background: #00ae66;
    border: none;
  }
}
</style>
